<template>
	<table-page
		v-if="columns.length && GroupDict != null"
		v-show="isLoaded"
		ref="translationTablePage"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'DtextId', ascending: 0 }"
		modelId="DtextId"
		headerTitle="dictionary3205"
		tooltipMsg="manualfordictio"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
		class="TranslationsTable"
	>
		<template #DtgroupId="list">{{ formatGroup(list.row.DtgroupId) }}</template>

		<template #DtextTypeId="list">{{ formatType(list.row.DtextTypeId) }}</template>

		<template #IsChecked="list">
			<boolean-display :boolValue="Boolean(list.row.IsChecked)"></boolean-display>
		</template>

		<template #ReadyToDelete="list">
			<boolean-display :boolValue="Boolean(list.row.ReadyToDelete)"></boolean-display>
		</template>

		<template #DtextExtendedEditor="list">
			<boolean-display :boolValue="Boolean(list.row.DtextExtendedEditor)"></boolean-display>
		</template>

		<template #DtextValueSk="list">
			<div v-html="list.row.DtextValueSk"></div>
		</template>

		<template #DtextValueCz="list">
			<div v-html="list.row.DtextValueCz"></div>
		</template>

		<template #DtextValuePl="list">
			<div v-html="list.row.DtextValuePl"></div>
		</template>

		<template #DtextValueDe="list">
			<div v-html="list.row.DtextValueDe"></div>
		</template>

		<template #DtextValueEn="list">
			<div v-html="list.row.DtextValueEn"></div>
		</template>

		<template #DtextValueHu="list">
			<div v-html="list.row.DtextValueHu"></div>
		</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import * as links from '@/router/links';
import { setDropdownOptions } from '@/components/general/utils';
import serviceEnums from '@/services/service/enums.service';
import serviceCommon from '../../services/service/common.service';
import serviceDictionary from '../../services/service/dictionary.service';
import BooleanDisplay from '@/components/common/boolean-display';

import { tableColumns } from './translation.table-data';

export default {
	name: 'translationTable',

	components: {
		TablePage,
		BooleanDisplay,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'Translation',
			columns: tableColumns,

			GroupDict: null,
			TypeDict: serviceEnums.getEnumDict('dictionaryTextType'),
		};
	},

	async created() {
		const groups = await serviceDictionary.getDictionaryTextGroupsEnum();
		this.GroupDict = groups.GroupDict;

		this.columns = this.prepareColumns(tableColumns, groups.GroupSelectorItems);
	},

	methods: {
		prepareColumns(columns, groups) {
			columns = setDropdownOptions(columns, 'DtgroupId', groups);

			let values = serviceEnums.getEnumForDropdown('dictionaryTextType', true);
			return setDropdownOptions(columns, 'DtextTypeId', values);
		},

		formatGroup(value) {
			if (value > 0) {
				return this.GroupDict[value].Text;
			}
			return '-';
		},

		formatType(value) {
			if (value >= 0) {
				return this.TypeDict[value].Text;
			}
			return '-';
		},

		refresh() {
			this.tableKey += 1;
		},

		redirectToDetail(itemId = 0) {
			this.$router.push({
				name: links.translationDetailName,
				params: { DtextId: itemId },
			});
		},

		handleCustomFilter() {
			serviceCommon.setCustomFilter(this.filters, this.$options.name);
		},
	},
};
</script>
<style lang="scss">
.TranslationsTable {
	.VueTables__filters-row > th:nth-child(1) {
		min-width: 55px;
		width: 55px;
	}
	.VueTables__filters-row > th:nth-child(2) {
		min-width: 55px;
		width: 55px;
	}
}
</style>
