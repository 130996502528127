var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columns.length && _vm.GroupDict != null
    ? _c("table-page", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoaded,
            expression: "isLoaded",
          },
        ],
        ref: "translationTablePage",
        staticClass: "TranslationsTable",
        attrs: {
          tableName: _vm.$options.name,
          columns: _vm.columns,
          filterModel: _vm.filterModel,
          requestFunction: _vm.requestFunction,
          defaultOrderBy: { column: "DtextId", ascending: 0 },
          modelId: "DtextId",
          headerTitle: "dictionary3205",
          tooltipMsg: "manualfordictio",
        },
        on: {
          addNewRecord: function ($event) {
            return _vm.redirectToDetail()
          },
          editRecord: function ($event) {
            return _vm.redirectToDetail($event)
          },
          filterChanged: function ($event) {
            return _vm.handleFilter($event)
          },
          removeFilters: function ($event) {
            return _vm.removeAllFilters($event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "DtgroupId",
              fn: function (list) {
                return [_vm._v(_vm._s(_vm.formatGroup(list.row.DtgroupId)))]
              },
            },
            {
              key: "DtextTypeId",
              fn: function (list) {
                return [_vm._v(_vm._s(_vm.formatType(list.row.DtextTypeId)))]
              },
            },
            {
              key: "IsChecked",
              fn: function (list) {
                return [
                  _c("boolean-display", {
                    attrs: { boolValue: Boolean(list.row.IsChecked) },
                  }),
                ]
              },
            },
            {
              key: "ReadyToDelete",
              fn: function (list) {
                return [
                  _c("boolean-display", {
                    attrs: { boolValue: Boolean(list.row.ReadyToDelete) },
                  }),
                ]
              },
            },
            {
              key: "DtextExtendedEditor",
              fn: function (list) {
                return [
                  _c("boolean-display", {
                    attrs: { boolValue: Boolean(list.row.DtextExtendedEditor) },
                  }),
                ]
              },
            },
            {
              key: "DtextValueSk",
              fn: function (list) {
                return [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(list.row.DtextValueSk) },
                  }),
                ]
              },
            },
            {
              key: "DtextValueCz",
              fn: function (list) {
                return [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(list.row.DtextValueCz) },
                  }),
                ]
              },
            },
            {
              key: "DtextValuePl",
              fn: function (list) {
                return [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(list.row.DtextValuePl) },
                  }),
                ]
              },
            },
            {
              key: "DtextValueDe",
              fn: function (list) {
                return [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(list.row.DtextValueDe) },
                  }),
                ]
              },
            },
            {
              key: "DtextValueEn",
              fn: function (list) {
                return [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(list.row.DtextValueEn) },
                  }),
                ]
              },
            },
            {
              key: "DtextValueHu",
              fn: function (list) {
                return [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(list.row.DtextValueHu) },
                  }),
                ]
              },
            },
          ],
          null,
          false,
          3383644886
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }