export const tableColumns = [
	{
		model: 'DtextId',
		i18n: 'id2541',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'DtgroupId',
		i18n: 'group',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'DtextDescription',
		i18n: 'description',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DtextValueSk',
		i18n: 'namesk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DtextValueCz',
		i18n: 'namecz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DtextValuePl',
		i18n: 'namepl',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DtextValueEn',
		i18n: 'nameen',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DtextValueDe',
		i18n: 'namede',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DtextValueHu',
		i18n: 'namehu',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DtextTypeId',
		i18n: 'type',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'IsChecked',
		i18n: 'checked',
		sortable: true,
		filter: 'boolean',
	},
	{
		model: 'ReadyToDelete',
		i18n: 'todelete',
		sortable: true,
		filter: 'boolean',
		hidden: true,
	},
	{
		model: 'DtextExtendedEditor',
		i18n: 'htmleditor',
		sortable: true,
		filter: 'boolean',
		hidden: true,
	},
];
